import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from '../components/global/layout/layout';

export const pageQuery = graphql`
  query DatenschutzQuery {
    site {
      siteMetadata {
        titleSuffix
        titleDivider
      }
    }
    privacy: prismicDatenschutz {
        data {
            privacy_policy {
                url
            }
        }
    }
  }
`

export default props => {
    return (
        <Layout wrapperClassName="bg-pattern-gold"
            title="Datenschutz"
            description="Hinweise zum Datenschutz bei der Verwendung unserer Webseite"
            titleSuffix={props.data.site.siteMetadata.titleSuffix}
            titleDivider={props.data.site.siteMetadata.titleDivider}>
            <section className="container py-5">
                <div className="row pt-5">
                    <div className="col-12 pt-5">
                        <h1 className="color-gold">Datenschutz</h1>
                        <p>
                            Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf
                            unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder eMail-Adressen) erhoben werden,
                            erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche
                            Zustimmung nicht an Dritte weitergegeben. Wir weisen darauf hin, dass die Datenübertragung im Internet
                            (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der
                            Daten vor dem Zugriff durch Dritte ist nicht möglich. Der Nutzung von im Rahmen der Impressumspflicht
                            veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderter
                            Werbung und Informationsmaterialien wird hiermit ausdrücklich widersprochen. Die Betreiber der Seiten
                            behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen,
                            etwa durch Spam-Mails, vor.
                        </p>

                        <h2 className="color-gold">Rechte der Betroffenen</h2>
                        <p>
                            Sie sind gemäß § 15 DSGVO jederzeit berechtigt, gegenüber der Schloss Westerburg GmbH &amp; Co. KG
                            (Vertragspartner) um umfangreiche Auskunftserteilung zu den zu Ihrer Person gespeicherten Daten zu
                            ersuchen.
                        </p>
                        <p>
                            Gemäß § 17 DSGVO können Sie jederzeit gegenüber dem Vertragspartner die Berichtigung, Löschung und
                            Sperrung einzelner personenbezogener Daten verlangen. Sie können darüber hinaus jederzeit ohne
                            Angabe von Gründen von Ihrem Widerspruchsrecht Gebrauch machen und die erteilte Einwilligungserklärung
                            mit Wirkung für die Zukunft abändern oder gänzlich widerrufen. Sie können den Widerruf entweder
                            postalisch, per E-Mail oder per Fax an den Vertragspartner übermitteln. Es entstehen Ihnen dabei
                            keine anderen Kosten als die Portokosten bzw. die Übermittlungskosten nach den bestehenden Basistarifen.
                            Mit Ablauf des laufenden Kalenderjahres werden Ihre personenbezogenen Daten automatisch gelöscht.
                        </p>

                        <h2 className="color-gold">Verwendung von Cookies</h2>
                        <p>
                            Um unsere Webseite für Sie optimal zu gestalten und fortlaufend verbessern zu können, verwenden wir
                            Cookies. Durch die weitere Nutzung der Webseite stimmen Sie der Verwendung von Cookies zu.
                        </p>

                        <h2 className="color-gold">Google Analytics</h2>
                        <p>In unserem Internetauftritt setzen wir Google Analytics ein. Hierbei handelt es sich um einen Webanalysedienst der Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA, nachfolgend nur „Google“ genannt.</p>
                        <p>Durch die Zertifizierung nach dem EU-US-Datenschutzschild („EU-US Privacy Shield“){' '}
                            <a className="unstyled font-weight-bold" href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active" target="_blank" rel="noopener noreferrer">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active</a>{' '}
                            garantiert Google, dass die Datenschutzvorgaben der EU auch bei der Verarbeitung von Daten in den USA eingehalten werden.</p>
                        <p>Der Dienst Google Analytics dient zur Analyse des Nutzungsverhaltens unseres Internetauftritts. Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Analyse, Optimierung und dem wirtschaftlichen Betrieb unseres Internetauftritts.</p>
                        <p>Nutzungs- und nutzerbezogene Informationen, wie bspw. IP-Adresse, Ort, Zeit oder Häufigkeit des Besuchs unseres Internetauftritts, werden dabei an einen Server von Google in den USA übertragen und dort gespeichert. Allerdings nutzen wir Google Analytics mit der sog. Anonymisierungsfunktion. Durch diese Funktion kürzt Google die IP-Adresse schon innerhalb der EU bzw. des EWR.</p>
                        <p>Die so erhobenen Daten werden wiederum von Google genutzt, um uns eine Auswertung über den Besuch unseres Internetauftritts sowie über die dortigen Nutzungsaktivitäten zur Verfügung zu stellen. Auch können diese Daten genutzt werden, um weitere Dienstleistungen zu erbringen, die mit der Nutzung unseres Internetauftritts und der Nutzung des Internets zusammenhängen.</p>
                        <p>Google gibt an, Ihre IP-Adresse nicht mit anderen Daten zu verbinden. Zudem hält Google unter{' '}
                            <a className="unstyled font-weight-bold" href="https://www.google.com/intl/de/policies/privacy/partners" target="_blank" rel="noopener noreferrer">https://www.google.com/intl/de/policies/privacy/partners</a>{' '}
                            weitere datenschutzrechtliche Informationen für Sie bereit, so bspw. auch zu den Möglichkeiten, die Datennutzung zu unterbinden.
                            Zudem bietet Google unter{' '}
                            <a className="unstyled font-weight-bold" href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank" rel="noopener noreferrer">https://tools.google.com/dlpage/gaoptout?hl=de</a>{' '}
                            ein sog. Deaktivierungs-Add-on nebst weiteren Informationen hierzu an. Dieses Add-on lässt sich mit den gängigen Internet-Browsern installieren und bietet Ihnen weitergehende Kontrollmöglichkeit über die Daten, die Google bei Aufruf unseres Internetauftritts erfasst. Dabei teilt das Add-on dem JavaScript (ga.js) von Google Analytics mit, dass Informationen zum Besuch unseres Internetauftritts nicht an Google Analytics übermittelt werden sollen. Dies verhindert aber nicht, dass Informationen an uns oder an andere Webanalysedienste übermittelt werden. Ob und welche weiteren Webanalysedienste von uns eingesetzt werden, erfahren Sie natürlich ebenfalls in dieser Datenschutzerklärung.</p>
                        <p>Alternativ können Sie mit Klick auf <span role="button" tabIndex="0" className="font-weight-bold cursor-pointer" onClick={() => typeof window !== 'undefined' ? window.gaOptOut() : undefined} onKeyDown={event => typeof window !== 'undefined' && event.keyCode === 13 ? window.gaOptOut() : undefined}>diesen Link</span> Google Analytics auf dieser Seite deaktivieren, indem ein Opt-Out-Cookie gesetzt wird.</p>

                        <hr></hr>
                        <p>
                            Unsere ausführliche Datenschutzerklärung finden Sie{' '}
                            <a className="unstyled" href={props.data.privacy.data.privacy_policy.url} target="_blank" rel="noopener noreferrer">hier</a>.
                        </p>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
